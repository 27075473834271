<template>
  <div class="container px-2 mx-auto dark:bg-gray-900">
    <div class="flex justify-between rounded-t-xl">
      <div class="flex items-center justify-start">
        <h2
          class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200"
        >
          Creador de Formularios
        </h2>
      </div>
      <div class="fixed right-10">
        <button
          @click.prevent="saveQuestionnaire"
          class="px-2 py-2 text-sm font-semibold shadow-md leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-b-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none"
        >
          <i class="icon-floppy" />Guardar
        </button>
      </div>
    </div>

    <div
      class="relative inline-block bg-gradient-to-t from-gray-300 to-gray-200 rounded-t-lg pl-2"
    >
      Tipo de Formulario
      <select
        class="bg-gradient-to-t from-blue-700 to-blue-600 text-white py-1 rounded-t-lg no-underline active:no-underline focus:no-underline"
        v-model="questionnaire.questionnaire_type"
        id=""
      >
        <option class="rounded-md no-underline" value="1">Modelo 1</option>
      </select>
    </div>
    <div class="flex items-center justify-center">
      <div class="block p-6 rounded-lg shadow-lg bg-white w-5/6">
        <div class="form-group mb-6">
          <label
            class="form-label font-bold mb-2 text-gray-700 flex items-start"
            >Título de Formulario</label
          >
          <input
            type="text"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-describedby="emailHelp"
            v-model="questionnaire.questionnaire_name"
            placeholder="Formulario sin título"
          />
        </div>
        <div class="form-group mb-6">
          <label
            for="exampleInputPassword1"
            class="form-label flex items-start font-bold mb-2 text-gray-700"
            >Descripción</label
          >
          <input
            type="text"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            v-model="questionnaire.questionnaire_description"
            placeholder="Descripción"
          />
        </div>
        <div>

          <button @click="addSection">
          <div class="flex items-center justify-center">
    <span class="relative inline-flex">
      <button type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow hover:bg-gray-200 rounded-md text-sky-500 bg-white dark:bg-slate-800 transition ease-in-out duration-150" >
        <i
            class="icon-plus-circled font-semibold not-italic  rounded-md"
            >Añadir Sección</i
          >
      </button>
      <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1" v-if="catContador>0">
        <!-- <span class="animate-ping w-6 h-6 absolute inline-flex rounded-full bg-yellow-300 opacity-75"></span> -->
        <span class="w-6 h-6 absolute inline-flex rounded-full bg-yellow-300 text-center items-center justify-center text-sm font-bold">{{catContador}}</span>
      </span>
    </span>
  </div>
</button>

        </div>
        <!-- end encabezado formulario -->
        <div v-if="toShow">
          <div
            v-for="(section, indexSection) in questionnaire.sections"
            :key="indexSection"
            class="mt-2 flex flex-col rounded"
          >
            <div class="md:w-5/6 border-t-2 border-l border-r border-blue-300 bg-white p-2 m-auto rounded">
              <div
                v-for="(title, indexTitulo) in section.title"
                :key="indexTitulo"
                class="md:flex md:justify-between"
              >
                <div class="md:w-4/5 border-2 border-gray-300 rounded">
                  <div class="rounded">
                    <input
                      type="text"
                      v-model="title.title_name"
                      id=""
                      class="md:w-3/4 m-auto text-5xl border-2 border-gray-300 rounded"
                    />
                  </div>
                  <div class="rounded">
                    <input
                      type="text"
                      v-model="title.title_description"
                      placeholder="Descripción"
                      class="md:w-3/4 m-auto text-xl border-2 border-gray-300 rounded"
                    />
                  </div>
                  <div>
                    <button @click.prevent="DelTitle(indexSection)">
                      Del Titulo
                    </button>
                  </div>
                </div>
                <div>
                  <!-- <button @click.prevent="DelTitle(indexSection)">
                Del Titulo
              </button> -->
                </div>
              </div>

              <!-- name -->
              <div class="w-full h-20 flex items-center justify-center space-x-4 flex-col md:flex-row">
                <!-- input -->
                  <div>
                  <input
                    type="text"
                    v-model="section.section_name"
                    placeholder="Escriba un titulo"
                    id=""
                    class="w-40 m-auto text-xl"
                  />
                  </div>
                  <div>
                  <input
                    type="text"
                    v-model="section.section_description"
                    placeholder="Escriba una descripción"
                    class="w-40 m-auto text-xl border-2 border-gray-300 rounded"
                  />
                  </div>
              </div>

            </div>
            <div
              class="md:w-5/6 flex items-center justify-center mx-auto b border-b-2 border-gray-300 rounded-b bg-white"
            >
              <!-- <button @click.prevent="addTitle(indexSection)" class="bg-yellow-100 hover:bg-yellow-200 px-2 rounded-t-md">
                <i class="icon-text-width not-italic">Título</i>
              </button> -->
              <button @click.prevent="addquestion(indexSection)" class="bg-green-100 hover:bg-green-200 px-2 rounded-t-md">
                <i class="icon-help not-italic">Pregunta</i>
              </button>
              <button @click.prevent="delSection(indexSection)" class="bg-gray-100 hover:bg-gray-200 px-2 rounded-t-md">
                <i class="icon-cancel not-italic">Eliminar Sección</i>
              </button>
            </div>
            <!-- preguntas -->
            
            <div
              v-for="(question, indexQuestion) in section.questions"
              :key="indexQuestion"
            >
              <div class="md:w-5/6 m-auto border-2 border-gray-300 rounded">               
                <div>
                  <button
                    @click.prevent="delQuestion(indexSection, indexQuestion)"
                  >
                    Del Pregunta
                  </button>

                </div>
                <!-- name -->
                <div class="rounded">
                  <div class="md:flex">
                    <!-- input -->
                    <div class="rounded">
                      <input
                        type="text"
                        v-model="question.question_statement"
                        id=""
                        class="w-5/6 m-auto text-5xl border-2 border-gray-300 rounded"
                      />
                    </div>
                    <div class="my-auto mx-auto">
                      <div class="">
                        <select
                          v-model="question.question_datatype"
                          @change="
                            typeChangeQuestions(
                              indexSection,
                              indexQuestion,
                              question
                            )
                          "
                          id="question_datatype"
                          class="border-2 border-blue-300 rounded p-2"
                        >
                          <option value="short_answer">Respuesta corta</option>
                          <option value="multiple_choice">
                            Opción múltiple
                          </option>
                          <option value="checkbox">
                            Casilla de verificación
                          </option>
                          <option value="drop_down">Lista desplegable</option>
                        </select>

                        <!-- <input type="text" v-model="question.question_datatype" placeholder="tipo de dato" class="w-5/6 m-auto text-xl border-2 border-red-600 rounded"> --></div>


                      
                    </div>
                  </div>

                  <!-- botones -->
                  <!-- <div class="border-2 border-gray-300 rounded">
                botones para texto
              </div> -->
                </div>
                

                <!-- <ul class="flex">
                  <li class="flex-1 mr-2">
                    <a class="text-center block border border-blue-500 rounded py-2 px-4 bg-blue-500 hover:bg-blue-500 text-white" href="#">Caja de texto</a>
                  </li>
                  <li class="flex-1 mr-2">
                    <a class="text-center block border border-blue-500 rounded py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white" href="#">Caja de texto</a>
                  </li>
                  <li class="flex-1 mr-2">
                    <a class="text-center block border border-blue-500 rounded py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white" href="#">Caja de texto</a>
                  </li>
                  <li class="flex-1 mr-2">
                    <a class="text-center block border border-blue-500 rounded py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white" href="#">Caja de texto</a>
                  </li>
                </ul> -->



                <!-- description -->
                <div class="border-2 mt-4 border-gray-300 rounded">
                  <!-- opciones -->
                  <div
                    v-for="(option, indexOption) in question.options"
                    :key="indexOption"
                  >
                    <div
                      v-if="question.question_datatype == 'multiple_choice'"
                      class="w-2/5"
                    >
                      <span
                        class="border-2 px-3 py-1 mr-2 bg-gray-200 border-gray-400 rounded-full"
                      ></span
                      ><input
                        type="text"
                        v-model="option.option_description"
                        class="border-2 mt-4 border-gray-300 rounded"
                      />
                      <button
                        class="ml-2"
                        @click.prevent="
                          delOption(indexSection, indexQuestion, indexOption)
                        "
                      >
                        DelOption
                      </button>
                      <button
                        class="ml-2"
                        @click.prevent="
                          addAction(indexSection, indexQuestion, indexOption)
                        "
                      >
                        More
                      </button>
                    </div>
                    <div
                      v-if="question.question_datatype == 'checkbox'"
                      class="w-2/5"
                    >
                      <span
                        class="border-2 px-3 py-1 mr-2 bg-gray-200 border-gray-400 rounded-lg"
                      ></span
                      ><input
                        type="text"
                        v-model="option.option_description"
                        class="border-2 mt-4 border-gray-300 rounded"
                      />
                      <button
                        class="ml-2"
                        @click.prevent="
                          delOption(indexSection, indexQuestion, indexOption)
                        "
                      >
                        DelOption
                      </button>
                      <button
                        class="ml-2"
                        @click.prevent="
                          addAction(indexSection, indexQuestion, indexOption)
                        "
                      >
                        More
                      </button>
                    </div>
                    <div
                      v-if="question.question_datatype == 'drop_down'"
                      class="flex w-4/5"
                    >
                      <div>
                        <span class="mr-2 text-lg p-2"
                          >{{ indexOption + 1 }}.
                        </span>
                        <input
                          type="text"
                          v-model="option.option_description"
                          class="border-2 p-1 border-gray-300 rounded"
                        />
                        <button
                          class="ml-2 p-2"
                          @click.prevent="
                            delOption(indexSection, indexQuestion, indexOption)
                          "
                        >
                          DelOption
                        </button>
                        <!-- <button class="ml-2" @click.prevent="addAction(indexSection, indexQuestion, indexOption)">Accion</button> -->
                        <!-- <button class="ml-2" @click.prevent="gotoSection(indexSection, indexQuestion, indexOption)">Ir a Seccion</button> -->
                      </div>
                      <div></div>
                      <div class="">
                        <div class="flex">
                          <div>
                            <select
                              v-model="option.option_goto_section_id"
                              @change="
                                changeOptions([
                                  indexSection,
                                  indexQuestion,
                                  indexOption,
                                  option.option_goto_section_id,
                                ])
                              "
                              id="question_datatype"
                              class="border-2 border-blue-300 rounded p-2"
                            >
                              <option value="input">Texto de entrada</option>
                              <option
                                v-for="(
                                  sectionOption, indexOptionSection
                                ) in questionnaire.sections"
                                :key="indexOptionSection"
                                :value="sectionOption.section_name"
                              >
                                {{ sectionOption.section_name }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="p-2"
                            v-if="option.option_goto_section_id == 'input'"
                          >
                            <span
                              class="underline text-xl text-gray-400 font-bold"
                              >{{ option.option_action_description }}</span
                            >
                          </div>
                          <!-- <input type="text" v-model="question.question_datatype" placeholder="tipo de dato" class="w-5/6 m-auto text-xl border-2 border-red-600 rounded"> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      @click.prevent="addOption(indexSection, indexQuestion)"
                    >
                      <i>add Opcion</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end ciclado de divs para formulario -->
        </div>
        <!-- v-if="toShow" -->
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

// import Swal from 'sweetalert2'

import { mapState } from "vuex";
// import Editor from '@tinymce/tinymce-vue';
export default {
  name: "ContentCreateForms",
  components: {
    // Editor
  },
  props: {},
  data() {
    return {
      visible: false,
      URL: process.env.VUE_APP_URL_FORM,
      toShow: true,
      catContador:0,
      questionnaire: {
        company_id: 1,
        questionnaire_type: 1,
        questionnaire_name: "",
        questionnaire_description: "",
        title: {
          title_name: "Title1",
          title_description: "Title description",
        },
        sections: [
          // {
          //   "section_name": "Sección sin título",
          //   "section_description": " ",
          //   "questions":[
          //       {
          //           "question_statement": "Pregunta",
          //           "question_datatype": " "
          //       }
          //   ]
          // }
        ],
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
},

  methods: {
    typeChangeQuestions(indexSection, indexQuestion, question) {
      this.toShow = false;
      // se hace de esta forma para poder re-enderizar la vista al ambial la propiedad del objeto
      // console.log(this.questionnaire.sections[indexSection].questions[indexQuestion])
      // console.log(this.questionnaire)
      this.toShow = true;
    },
    changeOptions(values) {
      // console.log(value[0])
      let indexSection = values[0];
      let indexQuestion = values[1];
      let indexOption = values[2];
      let value = values[3]; // valor seleccionado en el select
      // return;
      this.toShow = false;
      if (value === "input") {
        this.questionnaire.sections[indexSection].questions[
          indexQuestion
        ].options[indexOption].option_goto_section_id = "input";
        this.questionnaire.sections[indexSection].questions[
          indexQuestion
        ].options[indexOption].option_action_description = "Texto de entrada";
        this.questionnaire.sections[indexSection].questions[
          indexQuestion
        ].options[indexOption].option_action = "input";
      } else {
        // lo infiere directamiente por mutacion en el select
        // this.questionnaire.sections[indexSection].questions[indexQuestion].options[indexOption].option_goto_section_id=""
        this.questionnaire.sections[indexSection].questions[
          indexQuestion
        ].options[indexOption].option_action_description = "";
        this.questionnaire.sections[indexSection].questions[
          indexQuestion
        ].options[indexOption].option_action = "";
      }

      console.log(this.questionnaire);
      this.toShow = true;
    },
    addOption(indexSection, indexQuestion) {
      this.toShow = false;

      this.questionnaire.sections[indexSection].questions[
        indexQuestion
      ].options.push({
        option_description: "Opcion",
        option_goto_section_id: "",
      });
      this.toShow = true;
      console.log(this.questionnaire);
    },
    addSection() {
      this.questionnaire.sections.push({
        section_name: "",
        section_description: "",
      });
      this.catContador = this.questionnaire.sections.length
      console.log(this.questionnaire);
    },
    delSection(indexSection) {
      console.log(indexSection)
      this.toShow = false;
        this.questionnaire.sections.splice(indexSection, 1);
        this.catContador = this.questionnaire.sections.length
        this.toShow = true;
        
    },
    addquestion(index) {
      this.toShow = false;
      // console.log(index)
      if (this.questionnaire.sections[index].questions === undefined) {
        this.questionnaire.sections[index].questions = [
          {
            question_statement: "Pregunta",
            question_datatype: "multiple_choice",
            options: [
              {
                option_description: "Opcion",
                option_goto_section_id: "",
              },
            ],
          },
        ];
      } else {
        // console.log(this.questionnaire)
        this.questionnaire.sections[index].questions.push({
          question_statement: "Pregunta",
          question_datatype: "multiple_choice",
          options: [
            {
              option_description: "Opcion",
              option_goto_section_id: "",
            },
          ],
        });
      }
      this.toShow = true;
      console.log(this.questionnaire);
    },
    addTitle(index) {
      this.toShow = false;
      // console.log(index)
      if (this.questionnaire.sections[index].hasOwnProperty("title")) {
        this.toShow = true;
        return;
      }
      if (this.questionnaire.sections[index].title === undefined) {
        this.questionnaire.sections[index].title = [
          {
            title_name: "Título no definido",
            title_description: "Titulo sin descipcion",
          },
        ];
      } else {
        // console.log(this.questionnaire)
        this.questionnaire.sections[index].questions.push({
          question_statement: "Título no definido",
          question_datatype: "multiple_choice",
        });
      }
      this.toShow = true;
      console.log(this.questionnaire);
    },
    DelTitle(index) {
      this.toShow = false;
      if (this.questionnaire.sections[index].hasOwnProperty("title")) {
        delete this.questionnaire.sections[index].title;
        this.toShow = true;
        return;
      }
    },
    delOption(indexSection, indexQuestion, indexOption) {
      // console.log(indexOption)
      this.toShow = false;
      if (
        this.questionnaire.sections[indexSection].questions[indexQuestion]
          .options.length > 1
      ) {
        this.questionnaire.sections[indexSection].questions[
          indexQuestion
        ].options.splice(indexOption, 1);
        console.log(
          this.questionnaire.sections[indexSection].questions[indexQuestion]
            .options
        );
      }
      this.toShow = true;
    },
    addAction(indexSection, indexQuestion, indexOption) {
      // console.log(indexOption)
      this.toShow = false;
      // if(this.questionnaire.sections[indexSection].questions[indexQuestion].options.length > 1){
      this.questionnaire.sections[indexSection].questions[indexQuestion]
        .options[indexOption];
      console.log(
        this.questionnaire.sections[indexSection].questions[indexQuestion]
          .options
      );
      // }
      this.toShow = true;
    },
    gotoSection(indexSection, indexQuestion, indexOption) {
      // console.log(indexOption)
      this.toShow = false;
      // if(this.questionnaire.sections[indexSection].questions[indexQuestion].options.length > 1){
      this.questionnaire.sections[indexSection].questions[indexQuestion]
        .options[indexOption];
      console.log(
        this.questionnaire.sections[indexSection].questions[indexQuestion]
          .options
      );
      // }
      this.toShow = true;
    },
    delQuestion(indexSection, indexQuestion) {
      // console.log(indexOption)
      this.toShow = false;
      if (this.questionnaire.sections[indexSection].questions.length > 1) {
        this.questionnaire.sections[indexSection].questions.splice(
          indexQuestion,
          1
        );
        console.log(this.questionnaire.sections[indexSection].questions);
      }
      this.toShow = true;
    },
    async saveQuestionnaire() {
      // const URL = `${this.URL}store-questionnaire`; //se utiliza la misma ruta, que toma las ultimas transacciones del mayor
      // // y luego se invierten
      // const fetchConfig = {
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
      //   },
      // };
      let params = {
        questionnaire: this.questionnaire,
      };
      console.log(params);
      // try {
      // let rsAccount = await Axios.post(URL, params, fetchConfig);
      // console.log(rsAccount);
      // // return
      // let res = rsAccount.data.data;
      // // res.reverse()
      // this.objAccount = res;
      // console.log(this.objAccount);
    },
  },

  watch: {},
};
</script>

<style></style>
