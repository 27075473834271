<template>
	<div v-if="hasPermission">
		<ContentCreateForms />
	</div>
</template>

<script>
// @ is an alias to /src

// import Loans from '@/components/content/content-loans/ContentLoans.vue'
import ContentCreateForms from '@/components/content/forms/ContentCreateForms.vue'
import { getPermissions } from '../components/util/liblist'
export default {
	name: 'CreateForms',
	components: {
		ContentCreateForms,
	},
	data() {
		return {
			currentComponent: 'AccTransactions',
			'hasPermission': false,
		}
	},
	mounted() {
		if (getPermissions('formgenerator.create')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
